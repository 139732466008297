/**
*  Jquery Image Uploader 0.2
*  Copyright 2012, Kenneth Spencer
*/

import SbEvents from "SB/js/SbEvents";
import SB, {SbToken}  from "SB/js/SB";

(function($)
{
    $.fn.imageUploader = function(options)
    {
        return this.each(function()
        {
            new imageUploader(this, options);
        });
    };
})(jQuery);

/* Get location of JS file
*/
export default function imageUploader(selector, options)
{
    $.extend(this, new SbEvents());

    imageUploader.counter ++;
    this.options = {
        'preview' : {
            "default" : null, // default image
            defaultHTML: null, // If default HTML is desired instead of an image
            "image" : null,
            'size' : 'thumb'
        },
        'uploadPath' : null,
        'deletePath' : null,
        'debug' : true,
        'id' : 'image-uploader-' + imageUploader.counter,
        'fieldName' : 'image'
    };

    this.path = this.getPath();

    if (options) {
        this.options = $.extend(true, this.options, options);
    }

    this.instance = this.options.id.replace(/-/g, '_');

    imageUploader[this.instance] = this;
    imageUploader.instances.push(this);
        
    this.node = $(selector);    
    this.node.addClass('image-uploader');
    this.node.addClass('image-uploader-ready');

    this.renderPreview();
    this.htmlUploader();
}

imageUploader.counter = 0;
imageUploader.instances = [];

imageUploader.prototype.renderPreview = function()
{
    var opt = this.options;

    if (!this.preview) {
        let preview = '<div class="image-uploader-preview"></div>';
        this.preview = $(preview).appendTo(this.node);
    } else {
        this.preview.empty();
    }

    var $image;
    if (opt.preview.image) {
        $image = $('<img/>').appendTo(this.preview);
        $image.prop('src',  opt.preview.image);
    } else if (opt.preview.default) {
        $image = $('<img/>').appendTo(this.preview);
        $image.prop('src',  opt.preview.default);
    } else if (opt.preview.defaultHTML) {
        this.preview.append(opt.preview.defaultHTML);
    }
};

imageUploader.prototype.htmlUploader = function()
{
    var self = this;
    var opt = this.options;

    var progress = '<div class="image-uploader-progress"><div class="image-uploader-percenter"></div></div>';
    var remove = '<button type="button" class="image-uploader-delete">Delete</button>';

    var browse = '<div class="image-uploader-browse">';
    browse += '<label>Change';
    browse += '<input type="file" name="' + this.options.fieldName + '" accept="image/jpeg,image/gif,image/png"/>';
    browse += '</label>';
    browse += '</div>';

    this.remove = $(remove).appendTo(this.node);
    this.progress = $(progress).appendTo(this.node);
    this.browse = $(browse).appendTo(this.node);

    if (opt.deletePath === null || !opt.preview.image) {
        this.remove.css('display', 'none');
    }


    this.browse.css({
        'overflow' : 'hidden'
    });

    this.input = this.browse.find('input');

    this.input
    .on("change", function()
    {
        if (this.files.length) {
            var file = this.files[0];
            if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif") {
                if (self.options.uploadPath) {
                    self.upload(file);
                } else {
                    self.setPreview(file);
                }
                 self.trigger("change", file);
            }
        }
       //  this.value = '';
    });

    this.remove.on('click', function()
    {
        var $button = $(this);
        $button.fadeOut();
    
        var promise = self.preview.children().fadeOut().promise();

        if (!self.options.deletePath) {
            return;
        }
        SB.get(self.options.deletePath)
        .then(function(data)
        {
            promise.done(function()
            {
                self.options.preview.image = null;
                self.renderPreview();
            });

        }, function(e)
        {
            alert(e.message);
            self.preview.children().finish().fadeIn();
            $button.fadeIn();
            self.reset();
        });
    });

    this.node        
    .on('dragenter', function(evt)
    {   
        evt.stopPropagation();
        evt.preventDefault();
    })  
    .on('dragover', function(evt)
    {    
        evt.stopPropagation();
        evt.preventDefault();
    })
    .on('drop', function(evt)
    {
        evt.stopPropagation();
        evt.preventDefault();

        var dt = evt.originalEvent.dataTransfer;
        var files = dt.files;
        var file;
        if (files.length && (file = files[0]) && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif")) {
            if (self.options.uploadPath) {
                self.upload(file);
            } else {
                self.setPreview(file);
            }
            self.trigger("drop", file);
        }
    });

};

imageUploader.prototype.upload = function(file)
{
    var self = this;
    var formData = new FormData();
    formData.append(this.options.fieldName, file);

   // var size = file.size;
   // var filename = file.name;
   // var type = file.type;

    this.open();

    /* Fake File upload for testing
    */
 /*
    if (!this.options.uploadPath && this.options.debug) {
        this.setPreview(file);
        this.reset();
        return;
    }
*/

/*
    if (!this.options.uploadPath) {
        this.setPreview(file);
        return;
    }
*/

    /*  Upload the file to the server
     */
    var token = new SbToken();
    token.upload.on("progress", function(evt)
    {
        self.setProgress(evt.loaded,  evt.total);
    });
    
    
    SB.post(this.options.uploadPath, formData, token)
    .then(function(data)
    {
        var setPreview = function(src)
        {
            var image = new Image();
            image.onload = function()
            {
                self.preview.children().fadeOut(function()
                {
                    self.preview.empty();
                    var $image = $('<img style="display: none;"/>').appendTo(self.preview);
                    $image.prop('src', src);
                    $image.fadeIn();
                }); 
            };        
            image.src = src;
            self.options.preview.image = src;
            self.reset(true);
        };
    
        setPreview(data.images[self.options.preview.size]);
    },
    function(e)
    {
        alert(e.message);
        self.reset();
    });  


};

imageUploader.prototype.setPreview = function(file)
{
    var self = this;
  //  var opt = this.options;
    var reader = new FileReader();
    reader.onload = function(evt)
    {
        self.preview.children().fadeOut(function()
        {
            self.preview.empty();
            var $image = $('<img style="display: none;"/>').appendTo(self.preview);
            $image.prop('src', evt.target.result);
            $image.fadeIn();
            if (self.options.deletePath) {
                $(self.remove).stop().show();
            }
        }); 
    };
    reader.readAsDataURL(file);
};

imageUploader.prototype.toFile = function (dataURI)
{
    var split = dataURI.split(',');
    var type = split[0].match(/data:([^;]+)/)[1];

    var binary = atob(split[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }

    var blob = new Blob([new Uint8Array(array)], {
        type: type
    });
    return blob;
};

imageUploader.prototype.computeResize = function (image, width, height)
{
    var newWidth = image.widtt;
    var newHeight = image.height;

    if (width < image.width) {
        newWidth = width;
        newHeight = Math.floor((image.width / width) * image.height);            
    }
    
    if (height < newHeight) {
        newHeight = height;
        newWidth = Math.floor((newHeight / height) * newWidth);            
    }

    if (newWidth !== image.width || newHeight !== image.height) {
        return [newWidth, newHeight];    
    }

    return false;
};

imageUploader.prototype.resize = function (image, width, height, type)
{
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0, width, height);

    if (type) {
        return canvas.toDataURL(type);
    } else {
        return canvas.toDataURL();
    }
};    

imageUploader.prototype.getPath = function()
{
    var index;
    var list = $("script");
    for (var i = 0, script; (script = list[i]); i++) {
        if ((index = script.src.indexOf("/imageUploader.js")) !== -1) {
            return script.src.substr(0, index);
        }
    }
};

imageUploader.prototype.buildQuery = function(data)
{
    var array = [];
    for (var key in data) {
        array.push(key + '=' + encodeURIComponent(data[key]).replace("%20", "+", 'g'));
    }

    return array.join('&');
};

imageUploader.prototype.open = function()
{
    var self = this;
    var percenter = $(".image-uploader-percenter", this.node);
    percenter.css('width', 0);

    this.remove.fadeOut(200);

    this.browse.fadeOut(200, function()
    {
        self.progress.fadeIn(200);
    });

    this.node.removeClass('image-uploader-ready');
    this.node.addClass('image-uploader-uploading');
};


imageUploader.prototype.setProgress = function(position, size)
{
    var percent = (position / size) * 100;
    var percenter = $(".image-uploader-percenter", this.node);
    percenter.css('width', percent + '%');
    return true;
};

imageUploader.prototype.reset = function(success)
{
    var self = this;
    var opt = this.options;
    var percenter = $(".image-uploader-percenter", this.node);
    percenter.css('width', success ? '100%' : 0);

    setTimeout(function()
    {
        self.progress.stop().fadeOut(200, function()
        {
            self.browse.finish().fadeIn();
            if (opt.preview.image && opt.deletePath) {
                self.remove.finish().fadeIn();
            } else {
                self.remove.css('display', 'none');
            }

            self.node.removeClass('image-uploader-uploading');
            self.node.addClass('image-uploader-ready');
        });
    }, 100);
};

